import { PermissionKey, SystemFeature } from '@cca-infra/user-management/v1';
import { environment } from '../../environments/environment';
import { marker as t } from '@jsverse/transloco-keys-manager/marker';

export const enum IconType {
  mat = 'mat',
  fa = 'fa',
}

export interface UrlLog {
  eventName: string;
  url: string;
  timeStamp: number;
}

export interface UrlLogs {
  items: UrlLog[];
}

export interface NavigationLink {
  id: string | number;
  title: string;
  icon?: string;
  hoverIcon?: string;
  iconType?: IconType;
  link?: string;
  preload?: string;
  permission?: PermissionKey[];
  feature?: SystemFeature[];
  children?: NavigationLink[]; // prevents error in template where we cannot determine/cast type to NavigationLinkWithChildren
  realPermission?: boolean;
  hideInProduction?: boolean;
  accessFn?: () => boolean;
}

export interface NavigationLinkWithChildren extends NavigationLink {
  children?: NavigationLink[];
}

export function isNavigationLinkWithChildren(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  x: any,
): x is NavigationLinkWithChildren {
  return x && 'children' in x;
}

export type NavigationLinkType = NavigationLink | NavigationLinkWithChildren;

let id = 0;
export const navigationItems: NavigationLinkType[] = [
  {
    id: id++,
    title: t('web.menu.home'),
    link: '/home',
    icon: 'home',
    accessFn: () => {
      return true;
    },
  },
  {
    id: id++,
    title: t('web.menu.kpis'),
    link: '/dashboard-kpis',
    icon: 'bar-chart',
    permission: [
      PermissionKey.DashboardCustominternal,
      PermissionKey.DashboardCustomshipper,
      PermissionKey.DashboardCustomcarrier,
    ],
    feature: [SystemFeature.DashboardShipper],
  },
  {
    id: id++,
    title: t('web.menu.dashboard'),
    link: '/dashboard',
    icon: 'gauge-low',
    permission: [PermissionKey.DashboardGeneral],
  },
  {
    id: id++,
    title: t('web.menu.taskboard'),
    link: '/taskboard',
    icon: 'taskboard',
    permission: [PermissionKey.TaskGeneral],
  },
  {
    id: id++,
    title: t('web.menu.booking'),
    permission: [
      PermissionKey.BookingGeneral,
      PermissionKey.RequestGeneral,
      PermissionKey.OrderGeneral,
      PermissionKey.LaneGeneral,
      PermissionKey.TenderGeneral,
      PermissionKey.TenderRequests,
    ],

    children: [
      {
        id: id++,
        title: t('web.menu.booking'),
        link: '/booking',
        icon: 'booking',
        permission: [PermissionKey.BookingGeneral],
      },
      {
        id: id++,
        title: t('web.menu.request'),
        link: '/request',
        icon: 'request',
        permission: [PermissionKey.RequestGeneral],
      },
      {
        id: id++,
        title: t('web.menu.orders'),
        link: '/order',
        icon: 'list',
        permission: [PermissionKey.OrderGeneral],
      },
      {
        id: id++,
        title: t('web.menu.tenders'),
        link: '/tender',
        icon: 'messages-dollar',
        permission: [PermissionKey.TenderMenuitem],
      },
      {
        id: id++,
        title: t('web.menu.lane'),
        link: '/lane',
        icon: 'route',
        permission: [PermissionKey.LaneGeneral],
      },
    ],
  },
  {
    id: id++,
    title: t('web.menu.transport'),
    permission: [PermissionKey.TripPlanning],
    feature: [SystemFeature.TripPlanner],
    children: [
      // {
      //   id: id++,
      //   title: t('web.menu.createOrder'),
      //   link: '/create-orders',
      //   icon: 'booking',
      //   permission: [PermissionKey.CarrierorderGeneral],
      //   feature: [SystemFeature.CarrierTransportManagement],
      // },
      // {
      //   id: id++,
      //   title: t('web.menu.carrierOrder'),
      //   link: '/carrier-orders',
      //   icon: 'list',
      //   permission: [PermissionKey.CarrierorderGeneral],
      //   feature: [SystemFeature.CarrierTransportManagement],
      // },
      {
        id: id++,
        title: t('web.menu.trips'),
        link: '/trips',
        icon: 'list',
        permission: [PermissionKey.TripGeneral],
        feature: [SystemFeature.Trips],
      },
      {
        id: id++,
        title: t('web.menu.tripPlanner'),
        link: '/trip-planner',
        icon: 'columns',
        permission: [PermissionKey.TripPlanning],
        feature: [SystemFeature.TripPlanner],
      },
      // {
      //   id: id++,
      //   title: t('web.menu.customerGroups'),
      //   link: '/customer-groups',
      //   icon: 'users',
      //   permission: [PermissionKey.TripPlanning],
      //   feature: [SystemFeature.CarrierTransportManagement],
      // },
      // {
      //   id: id++,
      //   title: t('web.menu.customerLane'),
      //   link: '/customer-lane',
      //   icon: 'route',
      //   permission: [PermissionKey.CarrierorderGeneral],
      //   feature: [SystemFeature.CarrierTransportManagement],
      // },
      // {
      //   id: id++,
      //   title: t('web.menu.customerRate'),
      //   link: '/customer-rates',
      //   icon: 'euro-sign',
      //   permission: [PermissionKey.FinanceCtmsownedgrouprates],
      //   feature: [SystemFeature.CarrierTransportManagement],
      // },
      // {
      //   id: id++,
      //   title: t('web.menu.routes'),
      //   link: '/customer-routes',
      //   icon: 'road',
      //   hideInProduction: true,
      //   permission: [PermissionKey.InternalAdmin],
      // },
    ],
  },
  {
    id: id++,
    title: t('web.menu.organization'),
    permission: [PermissionKey.UserGeneral, PermissionKey.UsergroupGeneral],
    children: [
      {
        id: id++,
        title: t('web.menu.user'),
        link: '/user',
        icon: 'user',
        permission: [PermissionKey.UserGeneral],
      },
      {
        id: id++,
        title: t('web.menu.groups'),
        link: '/groups',
        icon: 'group',
        permission: [PermissionKey.UsergroupGeneral],
      },
    ],
  },
  {
    id: id++,
    title: t('web.menu.finance'),
    permission: [PermissionKey.FinanceRates, PermissionKey.InternalFinance],
    children: [
      {
        id: id++,
        title: t('web.menu.rates'),
        link: '/rates',
        icon: 'euro-sign',
        permission: [PermissionKey.FinanceRates],
      },
      {
        id: id++,
        title: t('web.menu.invoicing'),
        link: '/invoicing',
        icon: 'invoice-euro',
        permission: [PermissionKey.InternalFinance],
      },
    ],
  },
  {
    id: id++,
    title: t('web.menu.fleet'),
    permission: [
      PermissionKey.FleetGeneral,
      PermissionKey.LocationCarriercorridors,
      PermissionKey.FleetTracking,
    ],
    children: [
      {
        id: id++,
        title: t('web.menu.fleet'),
        link: '/fleet',
        icon: 'truck',
        permission: [PermissionKey.FleetGeneral],
      },
      {
        id: id++,
        title: t('web.menu.co3'),
        link: '/tracking-invites',
        icon: 'truck',
        permission: [PermissionKey.InternalGeneral],
      },
      {
        id: id++,
        title: t('web.menu.carrierCorridor'),
        link: '/carriercorridors',
        icon: 'road',

        // TODO: this might need to change to LocationCarrierCorridors?
        permission: [PermissionKey.LocationCarriercorridors],
      },
      {
        id: id++,
        title: t('web.menu.tracking'),
        link: '/tracking',
        icon: 'location-dot',
        permission: [PermissionKey.FleetTracking],
      },
      {
        id: id++,
        title: t('web.menu.analysis'),
        link: '/analysis',
        icon: 'map-location-dot',
        permission: [PermissionKey.AnalyticGeneral],
      },
    ],
  },
  {
    id: id++,
    title: t('web.menu.tools'),
    permission: [
      PermissionKey.LocationAddress,
      PermissionKey.UserCarrier,
      PermissionKey.UserShipper,
      PermissionKey.InternalMarketing,
    ],
    children: [
      {
        id: id++,
        title: t('web.menu.addressbook'),
        link: '/addressbook',
        icon: 'phonebook',
        permission: [PermissionKey.LocationAddress],
      },
      {
        id: id++,
        title: t('web.menu.releaseNotes'),
        link: '/release-notes',
        icon: 'no-more-task',
        permission: [
          PermissionKey.InternalMarketing,
          PermissionKey.UserCarrier,
          PermissionKey.UserShipper,
        ],
        realPermission: true,
      },
    ],
  },
  {
    id: id++,
    title: t('web.menu.admin'),
    permission: [PermissionKey.TranslationGeneral, PermissionKey.InternalAdmin],
    children: [
      {
        id: id++,
        title: t('web.menu.translation'),
        link: '/translation',
        icon: 'translation',
        permission: [PermissionKey.TranslationGeneral],
      },
      {
        id: id++,
        title: t('web.menu.languageGlossary'),
        link: '/glossary',
        icon: 'file-image',
        permission: [PermissionKey.TranslationGlossary],
      },
      {
        id: id++,
        title: t('web.menu.projection'),
        link: '/user-projection',
        icon: 'eye',
        permission: [PermissionKey.InternalAdmin],
        realPermission: true,
        hideInProduction: true,
      },
      {
        id: id++,
        title: t('web.menu.systemsettings'),
        link: '/system-settings',
        icon: 'Gear-Settings',
        permission: [PermissionKey.InternalAdmin],
        realPermission: true,
      },
      {
        id: id++,
        title: t('web.menu.terms'),
        link: '/terms',
        icon: 'file-certificate',
        permission: [PermissionKey.LegalGeneral],
        realPermission: true,
      },
      {
        id: id++,
        title: t('web.menu.logging'),
        link: '/logging',
        icon: 'memo',
        permission: [PermissionKey.InternalAdmin],
        realPermission: true,
      },
    ],
  },
]
  .map((x: NavigationLinkType) => {
    if (isNavigationLinkWithChildren(x)) {
      x.children = (x.children as NavigationLink[]).filter(
        (y: NavigationLink) =>
          y?.hideInProduction ? !environment.production : true,
      ) as NavigationLink[];
    }

    return x;
  })
  .filter((x) =>
    x?.hideInProduction ? !environment.production : true,
  ) as NavigationLink[];

export const flatMenuItems = navigationItems
  .map((item) => {
    return [item, ...(item.children ?? [])];
  })
  .flat();
